import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"
import ecanta from "../../assets/img/ecanta-basari-hikayesi.png"
import customer from "../../assets/img/selim-goksin.png"
import sorunlar from "../../assets/img/ecanta-sorunlar.png"
import google from "../../assets/img/ecanta-google-ads.png"
import blackFriday from "../../assets/img/ecanta-black-friday.png"
import anahtarKelimeler from "../../assets/img/ecanta-anahtar-kelimeler.png"
import seoIcon from "../../assets/img/seo-icon.png"
import dijitalStrateji from "../../assets/img/dijital-strateji-icon.png"
import googleAds from "../../assets/img/google-ads-logo.png"
import trafikIcon from "../../assets/img/trafik-icon.png"
import organikTrafik from "../../assets/img/ecanta-organik-trafik.png"
import cozum from "../../assets/img/ecanta-cozumler.png"
import OtherCaseStudy from "../../components/other-case"
import serife from "../../assets/img/teams/20.jpg"
import eelbeyglu from "../../assets/img/teams/11.jpg"
import melisa from "../../assets/img/teams/18.jpg"



const ReferancesDetail = () => (
    <Layout>
        <SEO title="eÇanta Success Story"
        description="Click here to learn about our successful marketing campaing of our brand eÇanta!"

        />
        <div className="referances-detail py60 container">
            <div className="col-md-6 detail-left">
                <b>SUCCESS STORY</b>
                <h1>eÇanta</h1>
                <p style={{ maxWidth: 300 }}>ecanta.com.tr began operating in 2002, and over the years developed an e-commerce website for popular bag and purse brands from all over the world for the customers in Turkses.</p>
                <p style={{ maxWidth: 300, fontSize: 15 }}>We worked with eCanta between the years of 2017 and 2020, and helped the brand to gain a serious momentum.</p>
                {/* <a className="svgLink" target="_blank" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48">
                    <g id="Group_11" data-name="Group 11" transform="translate(-432 -2296)">
                        <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(432 2296)" fill="none" stroke="#000" stroke-width="3">
                            <circle cx="24" cy="24" r="24" stroke="none" />
                            <circle cx="24" cy="24" r="22.5" fill="none" />
                        </g>
                        <path id="Path_80" data-name="Path 80" d="M422.384,556.893l-2.14,2.154,7.736,7.846-7.736,7.846,2.14,2.154,9.86-10Z" transform="translate(30.756 1753.107)" />
                    </g>
                </svg>
                    <span>Devamını Gör</span></a> */}
                <img className="pakmaya-headimg" style={{ position: 'absolute', height: 360, right: -90, top: 0, zIndex: -1 }} src={ecanta} />
            </div>
            <div className="col-md-6 referances-boxs">
                <div className="boxs" style={{ marginBottom: '0' }}>
                    <div className="box">
                        <span>+28k</span>
                        <p>Organic traffic increase of</p>
                    </div>
                    <div className="box">
                        <span>+%26</span>
                        <p>Reducing the cost of advertising</p>
                    </div>

                </div>
                <div className="provided">
                    <h3 style={{ margin: '10px 0' }}>Services Provided</h3>
                    <div className="provided-box">
                        <div className="box">
                            <Link to="/email-marketing/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 86 86">
                                <g id="Icons-_-Communication-_-Mail-heart" data-name="Icons-/-Communication-/-Mail-heart" transform="translate(0.169 -0.307)">
                                    <rect id="bound" width="86" height="86" transform="translate(-0.169 0.307)" fill="none" />
                                    <path id="Combined-Shape" d="M8.569,2H51.4a3.569,3.569,0,0,1,3.569,3.569V41.262A3.569,3.569,0,0,1,51.4,44.831H8.569A3.569,3.569,0,0,1,5,41.262V5.569A3.569,3.569,0,0,1,8.569,2Zm27.84,7.138c-2.3,0-4.985,2.605-6.425,4.283-1.439-1.678-4.127-4.283-6.425-4.283-4.068,0-6.425,3.173-6.425,7.21q0,6.71,12.849,14.2,12.849-7.5,12.849-13.92C42.834,12.6,40.477,9.138,36.409,9.138Z" transform="translate(13.236 5.138)" fill="#111" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Combined-Shape-2" data-name="Combined-Shape" d="M5.829,6.816,35.123,27.972,64.417,6.816a1.785,1.785,0,0,1,2.829,1.447V44.034a7.138,7.138,0,0,1-7.138,7.138H10.138A7.138,7.138,0,0,1,3,44.034V8.263A1.785,1.785,0,0,1,5.829,6.816Z" transform="translate(8.097 16.643)" fill="#111" fill-rule="evenodd" />
                                </g>
                            </svg>
                            E-Mail Marketing
                        </div>
                        <div className="box">
                            <Link to="/ui-ux-design/"></Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 76 76">
                                <g id="Icons-_-Design-_-Sketch" data-name="Icons-/-Design-/-Sketch" transform="translate(0.015 -0.307)">
                                    <rect id="bound" width="76" height="76" transform="translate(-0.015 0.307)" fill="none" />
                                    <path id="Path-48" d="M13.728,3H58.276L71,18.91H1Z" transform="translate(2.182 6.546)" fill-rule="evenodd" opacity="0.3" />
                                    <path id="Path-48-Copy" d="M71,8,36,46.184,1,8Z" transform="translate(2.182 17.456)" fill-rule="evenodd" />
                                </g>
                            </svg>
                            UI & UX Design
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3 style={{ margin: '10px 0' }}>Impressum</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Brand Manager:</li>
                            <li>Digital Ads:</li>
                            <li>SEO:</li>
                        </ul>
                        <ul>
                            <li><img src={melisa} />Melisa Akınsal</li>
                            <li><img src={serife} alt="Şerife Aytekin" />Şerife Aytekin</li>
                            <li><img src={eelbeyglu}  alt="Alaattin Emre Elbeyoğlu" />Emre Elbeyoğlu</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">

            <div className="content-section content row" >
                <div className="col-md-6">
                    <img className="pr100" src={sorunlar} />
                </div>
                <div className="col-md-6">
                    <span className="small">Success Story</span>
                    <h3>Problems</h3>
                    <p style={{ maxWidth: 429 }} className="small">- The biggest problem of ecanta.com.tr was the fact that the company was able to sell world famous brands at affordable prices. The significant decrease in prices made the customers question the legitimacy of the brand, because many thought that the products might be fake. As a result, the conversion rates were very low,</p>
                    <p style={{ maxWidth: 429 }} className="small">- ecanta.com.tr used a ready-made e-commerce infrastructure solution and this lat structure was not SEO compliant. Therefore, despite the product variety, it could not receive organic traffic.
                    </p>
                    <p style={{ maxWidth: 429 }} className="small">- Since the ads were not reflecting brand reliability, the potential conversions rate that was supposed to be achieved from them were also low.
                    </p>
                </div>
            </div>

            <div className="content-section content row py60" id="eCantaCozum">
                <div className="col-md-6">
                    <span className="small">Success Story</span>
                    <h3>Solution</h3>
                    <p style={{ maxWidth: 429 }}>As a solution, we first initiated a communication strategy to increase the reliability and recognition rates of the brand.</p>
                    <p style={{ maxWidth: 429 }} className="small">A campaign was planned  on advertisement, SEO and social media channels in order to show that ecanta.com.tr is actually Tuğça Canta, has been in this business for 29 years and is the official distributor of the brands that it sells.</p>
                </div>
                <div className="col-md-6">
                    <img src={cozum} />
                </div>
            </div>
        </div>
        <div className="testimonial-customer"  style={{ padding: '100px 13px', backgroundColor: '#F8EEE9' }}>
            <p className="pakmaya-center_text" style={{ fontSize: 24, maxWidth: 700, margin: '0 auto 20px' }}>“ Flatart helped us to become the industry leader on Google, and helped eCanta to rapidly reach the number one on Google. ”</p>
            <div className="author">
                <div>
                    <img src={customer}></img>
                </div>
                <div className="author-name">
                    Selim Göksin<br />
                    <span>CEO</span>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="text-center content py60">
                <span className="small">Success Story</span>
                <h2 style={{ margin: '7px auto 0', maxWidth: 700 }}>In the last year, we have reached 28,000 organic visitors from 1,200 monthly visitors.</h2>
            </div>

            <div className="content-section content row" id="ecantaTrafik">
                <div className="col-md-6">
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={trafikIcon} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p className="small">30-fold Growth in 1 Year</p>
                            <p>28.000 Organic</p>
                        </div>
                    </div>
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={seoIcon} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p className="small">Rise in Searches</p>
                            <p>2nd Place in a Google Search</p>
                        </div>
                    </div>

                    <p style={{ maxWidth: 429 }}>As a result of the on-page and off-page SEO work, organic traffic of the website has increased nearly 30 times in a year, reaching 28,000 monthly organic visitor levels.</p>
                    <p style={{ maxWidth: 429 }} className="small"> In addition to the traffic, eCanta is now always appearing on the second place, when someone searches for the brands that they are selling. It is right behind the brand’s own website.
                    </p>
                </div>
                <div className="col-md-6">
                    <img src={organikTrafik} />
                </div>
            </div>

            <div className="content-section content row py60" >
                <div className="col-md-6">
                    <img className="pr100" src={anahtarKelimeler} />
                </div>
                <div className="col-md-6">
                    <span className="small">Success Story</span>
                    <h3>Keywords</h3>
                    <p style={{ maxWidth: 429 }} className="small">- The biggest problem of ecanta.com.tr was the fact that the company was able to sell world famous brands at affordable prices. The significant decrease in prices made the customers question the legitimacy of the brand, because many thought that the products might be fake. As a result, the conversion rates were very low,</p>
                    <p style={{ maxWidth: 429 }} className="small">- ecanta.com.tr used a ready-made e-commerce infrastructure solution and this lat structure was not SEO compliant. Therefore, despite the product variety, it could not receive organic traffic.
                    </p>
                    <p style={{ maxWidth: 429 }} className="small">- Since the ads were not reflecting brand reliability, the potential conversions rate that was supposed to be achieved from them were also low.
                    </p>
                </div>
            </div>

            <div className="content-section content row" id="ecantaGoogleAds">
                <div className="col-md-6">
                    <img src={googleAds} alt="google ads" style={{ height: 38, width: 'auto', display: 'block', marginBottom: 20 }} />
                    <span className="small">Success Story</span>
                    <h3>Right click, right CPC cost at the right time with Google Ads scripts.</h3>
                    <p style={{ maxWidth: 429 }}>We used Google Ads scripts to manage to see more brands and products in shopping, search ads and GDN channels in Google Ads.</p>
                    <p style={{ maxWidth: 429 }} className="small">Thus, in accordance with the algorithm we have determined, the advertising panel has performed optimization works based on some basic metrics 24/7.</p>
                </div>
                <div className="col-md-6">
                    <img src={google} />
                </div>
            </div>

            <div className="text-center content py60">
                <span className="small">Success Story</span>
                <h2 style={{ margin: '7px auto 0', maxWidth: 700 }}>450% traffic increase, 650% conversion rate increase with our Black Friday digital strategy.</h2>
                <p style={{ margin: '20px auto 20px', maxWidth: 750 }}>We used Google Ads scripts to manage to see more brands and products in shopping, search ads and GDN channels in Google Ads.</p>
                <p style={{ margin: '0 auto', maxWidth: 500 }} className="small">Thus, in accordance with the algorithm we have determined, the advertising panel has performed optimization works based on some basic metrics 24/7.</p>
            </div>


            <div className="content-section content row" id="ecantaBlackFriday" >
                <div className="col-md-6">
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={trafikIcon} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p>450% Traffic Increase</p>
                            <p className="small">with our Digital Strategy</p>

                        </div>
                    </div>
                    <div className="inline_img-text">
                        <img style={{ width: 50 }} src={dijitalStrateji} alt="organik trafik grafik göstergesi" />
                        <div>
                            <p>650% Transformation</p>
                            <p className="small">with our Digital Strategy</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <img src={blackFriday} />
                </div>
            </div>
        </div>

        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
